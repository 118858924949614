/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useMemo } from 'react';
import {
  components,
  OptionProps,
  PlaceholderProps,
  ValueContainerProps,
  MenuProps,
  InputActionMeta,
  GroupProps,
  MenuListProps,
} from 'react-select';
import { RoutesByFeed_routes_RoutesConnection_nodes_Route as Route } from 'generated/RoutesByFeed';
import Checkbox, { CheckboxSize } from 'components/common/Checkbox';

import QuickSelect, {
  QuickSelectFooter,
  IOnChangeQuickSelect,
} from './quick-select';
import Select, {
  getPlaceholderColor as getSelectPlaceholderColor,
  SELECT_OVERLAY_STYLES,
} from '../Select';
import Bullet, { BulletSize } from '../../Bullet';

import Theme from '../../../../theme';
import { EntireRouteOption, RouteMention } from '../../../../types';
import Button from '../../Button';
import { FeedId } from '../../../../types/feeds';
import LineOption from './line-option';
import { useRoutesByFeedId } from '../../../../contexts/Routes';
import { routeToRouteMention } from '../../../../utils/route-mentions';
import { useFeedId } from 'contexts/FeedId';

type BusOption = {
  feedId: FeedId;
  label: string | null;
  key: string;
  bulletValue?: string;
  routeId?: string;
  isAllBoroughOption?: boolean;
};

type BusGroup = {
  borough: string;
  label: string;
  options: BusOption[];
};

type IChoices = BusOption | BusOption[] | null;

const MAX_BUS_BULLETS = 4;
const BROOKLYN_BUS_EXCEPTIONS = ['X27', 'X28', 'X37', 'X38'];
const QUEENS_BUS_EXCEPTIONS = ['J99', 'X63', 'X64', 'X68'];

const BOROUGH_KEYS = {
  MANHATTAN: 'M',
  BROOKLYN: 'B',
  BRONX: 'Bx',
  QUEENS: 'Q',
  STATEN_ISLAND: 'S',
  OTHER: '',
};

const BOROUGH_LABELS = {
  [BOROUGH_KEYS.MANHATTAN]: 'Manhattan',
  [BOROUGH_KEYS.BROOKLYN]: 'Brooklyn',
  [BOROUGH_KEYS.BRONX]: 'Bronx',
  [BOROUGH_KEYS.QUEENS]: 'Queens',
  [BOROUGH_KEYS.STATEN_ISLAND]: 'Staten Island',
  [BOROUGH_KEYS.OTHER]: 'Other',
};

const STRING_NUMBER_REGEX = /(\D+)|(\d+)/g;

const getKeysFromOptions = (busOptions?: BusOption[]): string[] =>
  busOptions?.reduce<string[]>((keys, busOption) => {
    if (!busOption.isAllBoroughOption) {
      keys.push(busOption.key);
    }

    return keys;
  }, []) ?? [];

const AllBoroughOption: React.FC<TOption> = ({
  busGroups,
  selectedValues,
  label,
  borough,
}) => {
  const boroughOptionKeys = getKeysFromOptions(
    busGroups?.find((o) => o.borough === borough)?.options,
  );

  const selectedValuesKeys = new Set(selectedValues.map((v) => v.key));

  const allBoroughOptionsSelected = boroughOptionKeys?.every((s) =>
    selectedValuesKeys.has(s),
  );

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding-bottom: 5px;
      `}
    >
      <Checkbox
        css={css`
          margin-right: 8px;
        `}
        size={CheckboxSize.medium}
        checked={allBoroughOptionsSelected}
        onChange={() => {}}
      />
      {label}
    </div>
  );
};

type TOption = {
  isAllBoroughOption?: boolean;
  key?: string;
  label: string;
  busGroups: BusGroup[];
  selectedValues: BusOption[];
  borough: string;
};

const Option: React.FC<{ children?: React.ReactNode } & any> = ({
  ...props
}) => {
  const { isFocused, isSelected, isDisabled, data, getValue, options } = props;
  const { isAllBoroughOption, key: dataKey, label: dataLabel } = data;
  return (
    <components.Option
      css={css`
        ${isAllBoroughOption
          ? `
        display: flex;
        margin-bottom: 8px;
        margin-left: 2px
        `
          : 'display: inline-block'}
      `}
      {...props}
    >
      {isAllBoroughOption ? (
        <AllBoroughOption
          busGroups={[...options]}
          selectedValues={[...getValue()]}
          borough={dataKey}
          label={dataLabel}
        />
      ) : (
        <LineOption
          focused={isFocused}
          selected={isSelected}
          disabled={!isSelected && isDisabled}
          option={data}
        />
      )}
    </components.Option>
  );
};

const Placeholder: React.FC<{ children?: React.ReactNode } & any> = (props) => {
  const lineOptions = props.getValue();
  return lineOptions.length ? null : <components.Placeholder {...props} />;
};

const ValueContainer: React.FC<{ children?: React.ReactNode } & any> = (
  props,
) => {
  const lineOptions = props.getValue() as EntireRouteOption[];
  let bullets: JSX.Element[] = [];
  let plusRemainingText: string = '';

  if (lineOptions.length > 0) {
    const numMaxBullets = MAX_BUS_BULLETS;
    bullets = lineOptions.slice(0, numMaxBullets).map((lineOption) => {
      return (
        <Bullet
          key={lineOption.routeId}
          size={BulletSize.medium}
          routeId={lineOption.routeId}
          style={{ display: 'block', marginRight: '3px' }}
        />
      );
    });

    plusRemainingText =
      lineOptions.length > numMaxBullets
        ? ` + ${lineOptions.length - numMaxBullets} more`
        : '';
  }

  return (
    <components.ValueContainer {...props}>
      {bullets}
      {plusRemainingText}
      {props.children}
    </components.ValueContainer>
  );
};

const MenuList: React.FC<{ children?: React.ReactNode } & any> = ({
  children,
  ...rest
}) => {
  const { getValue, options, isMulti, setValue } = rest;
  const flattenedOptions = options?.flatMap((o) => o.options);

  const isAgencyWide = (rest.selectProps as any)?.isAgencyWide ?? false;
  const enableQuickSelect =
    (rest.selectProps as any)?.enableQuickSelect ?? false;
  const onChange =
    (rest.selectProps as any)?.handleQuickSelectChange ?? (() => {});

  const menu = () => {
    if (enableQuickSelect) {
      return (
        <QuickSelect
          routes={getValue() as any}
          options={flattenedOptions}
          onChange={onChange}
          isAgencyWide={isAgencyWide}
          hideSeparator
          hideFooter
          addSpacing
        >
          {children}
        </QuickSelect>
      );
    }

    return children;
  };

  const footer = () => {
    if (enableQuickSelect) {
      return (
        <QuickSelectFooter
          routeOptions={flattenedOptions}
          onChange={onChange}
          isAgencyWide={isAgencyWide}
        />
      );
    }

    return (
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        {isMulti && (
          <Button
            primary
            css={css`
              margin-right: 8px;
            `}
            size="xsmall"
            type="button"
            onClick={() => {
              setValue(
                options
                  .flatMap((o) => o.options)
                  .filter((o) => !o.isAllBoroughOption),
                'select-option',
              );
            }}
          >
            Select All
          </Button>
        )}
        <Button
          size="xsmall"
          type="button"
          onClick={() => {
            setValue([], 'select-option');
          }}
        >
          Clear
        </Button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <components.MenuList {...rest}>{menu()}</components.MenuList>
      <div
        css={css`
          padding: 8px 20px;
        `}
      >
        {footer()}
      </div>
    </React.Fragment>
  );
};

const Menu: React.FC<{ children?: React.ReactNode } & any> = ({
  children,
  setValue,
  isMulti,
  options,
  ...rest
}) => {
  return (
    <components.Menu
      setValue={setValue}
      isMulti={isMulti}
      options={options}
      {...rest}
    >
      {children}
    </components.Menu>
  );
};

const Group: React.FC<{ children?: React.ReactNode } & any> = ({
  children,
  ...rest
}) => {
  return (
    <components.Group
      css={css`
        width: 100%;
        background-color: unset;

        div:last-child {
          padding: 12px 20px;

          div:last-child {
            padding: 0;
          }
        }
      `}
      {...rest}
    >
      {children}
    </components.Group>
  );
};

const GroupHeading: React.FC<unknown & { children?: React.ReactNode }> = (
  props: any,
) => (
  <components.GroupHeading
    css={css`
      text-transform: uppercase;
      color: ${Theme.colors.accent3};
      padding: 8px 20px;
    `}
    {...props}
  />
);

const getOverrideComponents = () => ({
  Option: (props: OptionProps<any>) => Option(props),
  Placeholder: (props: PlaceholderProps<any>) => Placeholder(props),
  ValueContainer: (props: ValueContainerProps<any>) => ValueContainer(props),
  Menu: (props: MenuProps<any>) => Menu(props),
  MenuList: (props: MenuListProps<any>) => MenuList(props),
  Group: (props: GroupProps<any>) => Group(props),
  GroupHeading: (props: GroupProps<any>) => GroupHeading(props),
});

const getOptionFromRoute = (route: Route): BusOption => ({
  feedId: FeedId.NYCTBus,
  label: route.shortName as string | null,
  bulletValue: route.gtfsId,
  key: route.gtfsId,
  routeId: route.gtfsId,
});

const getGroupedOptions = (
  busOptions: BusOption[],
  isMulti: boolean,
): BusGroup[] => {
  const boroughOptions: { [key: string]: BusOption[] } = {
    [BOROUGH_KEYS.BRONX]: [],
    [BOROUGH_KEYS.BROOKLYN]: [],
    [BOROUGH_KEYS.MANHATTAN]: [],
    [BOROUGH_KEYS.QUEENS]: [],
    [BOROUGH_KEYS.STATEN_ISLAND]: [],
    [BOROUGH_KEYS.OTHER]: [],
  };

  busOptions.forEach((busOption) => {
    if (busOption.label) {
      // We check the Bronx borough prefix before Brooklyn because both bus names start with a "B"
      if (busOption.label.startsWith(BOROUGH_KEYS.BRONX)) {
        boroughOptions[BOROUGH_KEYS.BRONX].push(busOption);
      } else if (
        busOption.label.startsWith(BOROUGH_KEYS.BROOKLYN) ||
        BROOKLYN_BUS_EXCEPTIONS.includes(busOption.label)
      ) {
        boroughOptions[BOROUGH_KEYS.BROOKLYN].push(busOption);
      } else if (busOption.label.startsWith(BOROUGH_KEYS.MANHATTAN)) {
        boroughOptions[BOROUGH_KEYS.MANHATTAN].push(busOption);
      } else if (
        busOption.label.startsWith(BOROUGH_KEYS.QUEENS) ||
        QUEENS_BUS_EXCEPTIONS.includes(busOption.label)
      ) {
        boroughOptions[BOROUGH_KEYS.QUEENS].push(busOption);
      } else if (busOption.label.startsWith(BOROUGH_KEYS.STATEN_ISLAND)) {
        boroughOptions[BOROUGH_KEYS.STATEN_ISLAND].push(busOption);
      } else {
        boroughOptions[BOROUGH_KEYS.OTHER].push(busOption);
      }
    }
  });

  return Object.keys(boroughOptions).reduce<BusGroup[]>(
    (boroughGroups, borough) => {
      if (boroughOptions[borough].length) {
        const boroughLabel = BOROUGH_LABELS[borough];

        boroughGroups.push({
          borough,
          label: boroughLabel,
          options: [
            ...(isMulti
              ? [
                  {
                    feedId: FeedId.NYCTBus,
                    key: borough,
                    label: `All ${boroughLabel} Routes`,
                    isAllBoroughOption: true,
                  },
                ]
              : []),
            ...boroughOptions[borough].sort((a, b) => {
              // The regex used here splits bus names into groups of strings and numbers
              // ex: 'Bx44-SBS' would give us the result ['Bx', '44', '-SBS']
              const aStringNumberGroups = a.label?.match(STRING_NUMBER_REGEX);
              const bStringNumberGroups = b.label?.match(STRING_NUMBER_REGEX);

              if (aStringNumberGroups?.[0] === bStringNumberGroups?.[0]) {
                return (
                  parseInt(aStringNumberGroups?.[1] ?? '0', 10) -
                  parseInt(bStringNumberGroups?.[1] ?? '0', 10)
                );
              }

              return (aStringNumberGroups?.[0] ?? '').localeCompare(
                bStringNumberGroups?.[0] ?? '',
              );
            }),
          ],
        });
      }

      return boroughGroups;
    },
    [],
  );
};

const BusRouteSelector: React.FC<
  {
    id?: string;
    routes: RouteMention | RouteMention[];
    onChange: IOnChangeQuickSelect;
    className?: string;
    classNamePrefix?: string;
    disabledRoutes?: RouteMention[];
    hasControlBoxShadow?: boolean;
    hasControlBorder?: boolean;
    isMulti?: boolean;
    isClearable?: boolean;
    placeholder?: string;
    disabled?: boolean;
    selectProps?: {
      selectStyles?: any;
    };
    enableQuickSelect?: boolean;
    isAgencyWide?: boolean;
  } & { children?: React.ReactNode }
> = ({
  id,
  routes,
  onChange,
  className,
  classNamePrefix,
  hasControlBorder = true,
  hasControlBoxShadow = false,
  isMulti = false,
  placeholder = '',
  disabled = false,
  disabledRoutes,
  selectProps = {
    selectStyles: {},
  },
  enableQuickSelect = false,
  isAgencyWide = false,
  ...rest
}) => {
  const [searchText, setSearchText] = useState('');
  const feedId = useFeedId();
  const allRoutes = useRoutesByFeedId(feedId);

  const allRouteOptions =
    allRoutes?.map((route) => getOptionFromRoute(route)) || [];

  const allRouteIds = Array.isArray(routes)
    ? routes.map((route) => route.routeId)
    : [routes.routeId];
  const selectedRouteOptions = allRouteOptions.filter((route) =>
    allRouteIds.includes(route.routeId ?? ''),
  );

  const groupedOptions = getGroupedOptions(allRouteOptions, isMulti);

  const overrideComponents = useMemo(
    () => getOverrideComponents(),
    // We are disabling the linter here because we don't want the `overrideComponents` to be
    // re-created on every render. Which would cause the menu to automatically scroll to the top.
    // eslint-disable-next-line
    [allRoutes.length],
  );

  return (
    <div className={className}>
      <Select
        id={id}
        classNamePrefix={classNamePrefix}
        isDisabled={disabled}
        isMulti={isMulti}
        isLoading={!allRoutes || !allRoutes.length}
        hasControlBoxShadow={hasControlBoxShadow}
        hasControlBorder={hasControlBorder}
        isSearchable
        isClearable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMulti && !enableQuickSelect}
        controlShouldRenderValue={false}
        tabSelectsValue={false}
        placeholder={placeholder}
        options={groupedOptions}
        enableQuickSelect={enableQuickSelect}
        isAgencyWide={isAgencyWide}
        handleQuickSelectChange={onChange}
        getOptionValue={(option: BusOption) => option.key}
        isOptionDisabled={(option: BusOption) =>
          !!disabledRoutes?.find(
            (disabledRoutes) => disabledRoutes.routeId === option.routeId,
          )
        }
        value={selectedRouteOptions}
        onInputChange={(value: string, inputAction: InputActionMeta) => {
          if (inputAction.action === 'input-change') {
            setSearchText(value);
          }

          if (inputAction.action === 'menu-close') {
            setSearchText('');
          }
        }}
        inputValue={searchText}
        // eslint-disable-next-line consistent-return
        onChange={(choices: IChoices, { option }: { option: BusOption }) => {
          if (!choices) {
            return onChange([]);
          }

          if (Array.isArray(choices)) {
            let allChoices = choices;
            const allBoroughOption = allChoices.find(
              (c) => c.isAllBoroughOption,
            );

            if (allBoroughOption) {
              const borough = groupedOptions.find(
                (b) => b.borough === allBoroughOption.key,
              );
              const boroughOptionKeys = new Set(
                getKeysFromOptions(borough?.options),
              );
              const previouslySelectedKeys = new Set(
                getKeysFromOptions(allChoices),
              );

              const allBoroughOptionsSelected = Array.from(
                boroughOptionKeys,
              ).every((s) => previouslySelectedKeys.has(s));

              if (allBoroughOptionsSelected && option) {
                allChoices = allChoices.filter(
                  (c) => !boroughOptionKeys.has(c.key),
                );
              } else {
                allChoices.push(
                  ...(borough?.options?.filter(
                    (o) =>
                      !allChoices.find((c) => c.routeId === o.routeId) &&
                      !o.isAllBoroughOption,
                  ) ?? []),
                );
              }
            }

            const routeMentions = allChoices
              .map((choice) => {
                const route = allRoutes.find(
                  (_route) => _route.gtfsId === choice.routeId,
                );

                return route ? routeToRouteMention({ route }) : undefined;
              })
              .filter((mention): mention is RouteMention => !!mention);
            return onChange(routeMentions);
          }

          const route = allRoutes.find(
            (_route) => _route.gtfsId === choices.routeId,
          );

          if (route) {
            return onChange([routeToRouteMention({ route })]);
          }
        }}
        components={overrideComponents}
        styles={{
          menu: (provided: {}) => ({
            ...provided,
            marginTop: '-1px',
            borderRadius: '0 0 4px 4px',
            boxShadow: 'none',
            border: `1px solid ${Theme.colors.accent3}`,
            borderTop: '1px solid #EAEAEA',
            zIndex: 100,
            ...SELECT_OVERLAY_STYLES.menu,
          }),
          menuList: (provided: {}) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingRight: '1px',
            paddingLeft: '1px',
            paddingTop: 0,
          }),
          option: () => ({
            background: 'none',
            cursor: 'pointer',
            padding: 0,
            width: 'auto',
          }),
          valueContainer: (provided: {}) => ({
            ...provided,
            display: 'flex',
            paddingLeft: Theme.spacing.small,
          }),
          placeholder: (
            provided: {},
            state: { isFocused: boolean; isDisabled: boolean },
          ) => ({
            ...provided,
            paddingLeft: 0,
            color: getSelectPlaceholderColor(state.isFocused, state.isDisabled),
          }),
          control: (provided: {}, state: { isFocused: boolean }) => {
            return {
              boxShadow: `inset 0 0 4px ${Theme.colors['border-dark']}`,
              ...(state.isFocused ? SELECT_OVERLAY_STYLES.control : {}),
            };
          },
          ...selectProps.selectStyles,
        }}
        {...rest}
      />
    </div>
  );
};

export default BusRouteSelector;
