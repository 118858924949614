/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

import { getClearedPlannedWorkMessageTypeDescriptionsByFeed } from 'utils/message-type-display';
import * as commonStyles from '../styles';

import PageMeta from '../PageMeta';
import PageHeading from '../../scaffolding/PageHeading';
import AppLink, { ApplinkStyles } from '../app-link';

import Loader from '../skeletons/PageWithTable';
import { ButtonList } from '../../scaffolding/PageHeading.styled';
import { useFeedId } from '../../../contexts/FeedId';

import PlannedWorkTable, {
  TPlannedWorkTableSortingTypes,
} from './PlannedWorkTable';
import PlannedWorkFilters from './PlannedWorkFilters';

import { PlannedWorkStatus } from '../../../generated/global-types';
import NoMessages from '../NoMessages';

import usePlannedWorkQuery from '../../../hooks/use-planned-work-query';

const PlannedWorkPage: React.FC<{
  statuses: PlannedWorkStatus[];
  page: string;
  pageTitle: string;
  children?: React.ReactNode;
}> = ({ statuses, page, pageTitle }) => {
  const feedId = useFeedId();
  const {
    query: { loading, data },
    setPage,
    setOrderBy,
    setDirection,
    pageNum,
    perPage,
    orderBy,
    direction,
    hasFilter,
  } = usePlannedWorkQuery(statuses);
  const categoryOptions =
    getClearedPlannedWorkMessageTypeDescriptionsByFeed(feedId);

  const messages = data?.searchPlannedWorks?.results || [];
  const totalCount = data?.searchPlannedWorks?.totalCount ?? 0;
  return (
    <section css={commonStyles.container}>
      <PageMeta title={`${pageTitle} Planned Work`} />
      <PageHeading
        breadcrumbs={[
          'Messages',
          {
            to: `/${feedId}/planned-work`,
            label: 'Planned Work',
          },
        ]}
        title={pageTitle}
      >
        <ButtonList>
          <AppLink
            to={`/${feedId}/planned-work/compose`}
            styleVariations={[
              ApplinkStyles.PRIMARY_BUTTON,
              ApplinkStyles.LARGE_BUTTON,
            ]}
          >
            Compose
          </AppLink>
        </ButtonList>
      </PageHeading>
      <div css={commonStyles.pageContent}>
        <div
          css={[
            commonStyles.flexCol,
            css`
              width: 100%;
            `,
          ]}
        >
          <Loader loading={loading && !messages.length}>
            <React.Fragment>
              <PlannedWorkFilters
                feedId={feedId}
                showStatus={false}
                categoryOptions={categoryOptions}
                disabled={loading}
                showLive={false}
                page={page}
              />
              {messages.length ? (
                <PlannedWorkTable
                  onNextClick={() => setPage(`${pageNum + 1}`)}
                  onPrevClick={() => setPage(`${pageNum - 1}`)}
                  onSortClick={({ orderBy: newOrderBy, isDesc: newIsDesc }) => {
                    setOrderBy(newOrderBy);
                    setDirection(newIsDesc ? 'desc' : 'asc');
                    setPage('1');
                  }}
                  feedId={feedId}
                  page={pageNum}
                  perPage={perPage}
                  orderBy={orderBy as TPlannedWorkTableSortingTypes}
                  isDesc={direction === 'desc'}
                  messages={messages}
                  totalCount={totalCount}
                  showEdit={false}
                  showVisibleOn={false}
                  loading={loading}
                />
              ) : (
                <div
                  css={css`
                    flex: 1 1 100%;
                    display: flex;
                  `}
                >
                  {hasFilter ? (
                    <NoMessages
                      heading="No Messages"
                      message="No messages match provided filters."
                      ctaLabel="Clear Filters"
                      ctaLink={`/${feedId}/planned-work/${page}`}
                    />
                  ) : (
                    <NoMessages
                      heading="All Clear!"
                      message="There is no active Planned Work."
                      ctaLabel="Compose Planned Work"
                      ctaLink={`/${feedId}/planned-work/compose`}
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          </Loader>
        </div>
      </div>
    </section>
  );
};

export default PlannedWorkPage;
